import {move} from '@wandb/weave/common/util/data';
import {ID} from '@wandb/weave/common/util/id';
import {freeze, original, produce} from 'immer';
import _, {cloneDeep, findIndex, isEqual} from 'lodash';
import {getType} from 'typesafe-actions';

import {SectionPanelSorting} from '../../components/PanelBank/types';
import {Settings} from '../../components/WorkspaceDrawer/Settings/types';
import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import {
  findNextPanelLoc,
  GRID_COLUMN_COUNT,
  GRID_ITEM_DEFAULT_HEIGHT,
  GRID_ITEM_DEFAULT_WIDTH,
} from '../../util/panelbankGrid';
import {ReportSpecVersion} from '../../util/report';
import {keyFromString} from '../../util/runs';
import {Key} from '../../util/runTypes';
import * as SM from '../../util/selectionmanager';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as CustomRunColorsActions from './customRunColors/actions';
import * as DiscussionCommentActions from './discussionComment/actions';
import * as FilterActions from './filter/actions';
import * as GroupSelectionsActions from './groupSelections/actions';
import * as GroupSelectionsActionsInternal from './groupSelections/actionsInternal';
import {handleUpdateViewSpec} from './handleUpdateViewSpec';
import * as MarkdownBlockActions from './markdownBlock/actions';
import * as Normalize from './normalize';
import {lookupPart, StateType} from './normalizerSupport';
import * as PanelActions from './panel/actions';
import * as PanelTypes from './panel/types';
import * as PanelUtils from './panel/utils';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import * as PanelBankSectionConfigActionsInternal from './panelBankSectionConfig/actionsInternal';
import * as PanelBankSectionConfigTypes from './panelBankSectionConfig/types';
import {PanelBankSectionConfigNormalized} from './panelBankSectionConfig/types';
import {movePanelAlphabeticallyInSection} from './panelBankSectionConfig/utils';
import * as PanelSettingsActions from './panelSettings/actions';
import {
  ActionType,
  deleteParts,
  removeHistoryForObject,
  replacePart,
  ViewReducerState,
} from './reducerSupport';
import * as ReportActions from './report/actions';
import * as RunSetActions from './runSet/actions';
import * as SectionActions from './section/actions';
import * as SortActions from './sort/actions';
import * as TempSelectionsActions from './tempSelections/actions';
import * as TempSelectionsActionsInternal from './tempSelections/actionsInternal';

/**
 * Internal use, but must be implemented for all undoable actions.
 * Should apply action, and return an inverse action (an action that
 * does the original action). The inverse action must be inversible
 * itself.
 *
 * This function is specifically for actions being processed with immer.
 * Immer is a known souce of perf issues when modifying large state objects.
 * If you encounter actions performing poorly due to immer, please refactor
 * to use the non-immer handler.
 *
 * Warning: 'state' here is actually an immer draft, which is updated with mutations.
 *    Sometimes you'll need a copy of the previous state value before mutating it.
 *    If so, use immer's `original` to read from the original, immutable state object.
 *    Avoid `cloneDeep` if possible, as it can be very expensive in large workspace.
 *    You can also use `original` to fix the "cannot perform 'get' on revoked proxy"
 *    errors that sometimes pop up if you're using the redux devtools.
 **/
export function applyAndMakeInverseActionImmer(
  state: ViewReducerState,
  action: ActionType
) {
  switch (action.type) {
    case getType(Actions.noop): {
      return Actions.noop();
    }
    case getType(ActionsInternal.undoableUpdateViewSpec): {
      const {ref, prevSpec, newSpec, wholeIsDefinitelyNew} = action.payload;
      handleUpdateViewSpec(state, ref.id, newSpec, {
        wholeIsDefinitelyNew,
      });
      return ActionsInternal.undoableUpdateViewSpec(ref, {
        prevSpec: newSpec, //  Note how the specs are swapped for undo action
        newSpec: prevSpec,
        wholeIsDefinitelyNew: false, // we can assume data is already in redux
      });
    }
    case getType(SectionActions.removeRunSet): {
      const {ref, runSetRef} = action.payload;
      const section = state.parts.section[ref.id];
      const index = findIndex(
        section.runSetRefs,
        r => r.viewID === runSetRef.viewID && r.id === runSetRef.id
      );
      if (index === -1) {
        throw new Error('invalid action');
      }
      section.runSetRefs.splice(index, 1);
      if (
        section.openRunSet != null &&
        section.openRunSet >= section.runSetRefs.length
      ) {
        section.openRunSet =
          section.openRunSet === 0 ? undefined : section.runSetRefs.length - 1;
      }
      const runSet = Normalize.denormalize(state.parts, runSetRef);
      removeHistoryForObject(state, runSetRef);
      deleteParts(state, runSetRef);
      return SectionActions.insertRunSet(ref, index, runSet);
    }
    case getType(SectionActions.duplicateRunSet): {
      const {ref, runSetRef} = action.payload;
      const section = state.parts.section[ref.id];
      const sourceRunSet = Normalize.denormalize(state.parts, runSetRef);
      const partRef = Normalize.addObj(state.parts, 'runSet', ref.viewID, {
        ...sourceRunSet,
        id: ID(9),
        name: `Run set ${section.runSetRefs.length + 1}`,
      });
      section.runSetRefs.push(partRef);
      section.openRunSet = section.runSetRefs.length - 1;
      return SectionActions.removeRunSet(ref, partRef);
    }
    case getType(SectionActions.reorderRunSet): {
      const {ref, indexFrom, indexTo} = action.payload;

      const section = state.parts.section[ref.id];

      const openID =
        section.openRunSet != null && section.runSetRefs[section.openRunSet].id;

      section.runSetRefs = move(section.runSetRefs, indexFrom, indexTo);
      // If there is an open section maintain it.
      if (section.openRunSet != null) {
        const idx = section.runSetRefs.findIndex(
          runSetRef => runSetRef.id === openID
        );
        section.openRunSet = idx;
      }

      return SectionActions.reorderRunSet(ref, indexTo, indexFrom);
    }
    case getType(SectionActions.insertRunSet): {
      const {ref, runSet, index} = action.payload;
      const section = state.parts.section[ref.id];

      const runSetRef = Normalize.addObj(
        state.parts,
        'runSet',
        ref.viewID,
        runSet
      );

      section.runSetRefs.splice(index, 0, runSetRef);
      return SectionActions.removeRunSet(ref, runSetRef);
    }
    case getType(SectionActions.setActiveIndex): {
      const {ref, index} = action.payload;
      const section = state.parts.section[ref.id];
      const prevIndex = section.openRunSet;
      section.openRunSet = index;
      return SectionActions.setActiveIndex(ref, prevIndex);
    }
    case getType(SectionActions.setHideRunSets): {
      const {ref, hide} = action.payload;
      const section = state.parts.section[ref.id];
      const prevHide = section.hideRunSets ?? false;
      section.hideRunSets = hide;
      return SectionActions.setHideRunSets(ref, prevHide);
    }
    case getType(SectionActions.setOpen): {
      const {ref, open} = action.payload;
      const section = state.parts.section[ref.id];
      const prevOpen = section.openViz || false;
      section.openViz = open;
      return SectionActions.setOpen(ref, prevOpen);
    }
    case getType(SectionActions.setName): {
      const {ref, name} = action.payload;
      const section = state.parts.section[ref.id];
      const prevName = section.name || '';
      section.name = name;
      return SectionActions.setName(ref, prevName);
    }
    case getType(MarkdownBlockActions.setContent): {
      const {ref, content} = action.payload;
      const block = state.parts['markdown-block'][ref.id];
      if (block == null) {
        return Actions.noop();
      }
      const prevContent = block.content;
      block.content = content;
      return MarkdownBlockActions.setContent(ref, prevContent);
    }
    case getType(MarkdownBlockActions.setCollapsed): {
      const {ref, collapsed} = action.payload;
      const block = state.parts['markdown-block'][ref.id];
      const prevCollapsed = !!block.collapsed;
      block.collapsed = collapsed;
      return MarkdownBlockActions.setCollapsed(ref, prevCollapsed);
    }
    case getType(PanelSettingsActions.set): {
      const {ref, panelSettings} = action.payload;
      const prevSettings = cloneDeep(state.parts.panelSettings[ref.id]);
      state.parts.panelSettings[ref.id] = panelSettings;
      return PanelSettingsActions.set(ref, prevSettings);
    }
    case getType(PanelSettingsActions.update): {
      const {ref, panelSettingsUpdate} = action.payload;
      const prevSettings = cloneDeep(state.parts.panelSettings[ref.id]);
      Object.assign(state.parts.panelSettings[ref.id], panelSettingsUpdate);
      return PanelSettingsActions.set(ref, prevSettings);
    }
    case getType(PanelSettingsActions.setLocalAndWorkspacePanelSettings): {
      const {ref, workspaceRef, localPanelSettings, workspacePanelSettings} =
        action.payload;
      const prevLocalSettings = cloneDeep(state.parts.panelSettings[ref.id]);
      const prevWorkspaceSettings = cloneDeep(
        state.parts.panelSettings[workspaceRef.id]
      );
      state.parts.panelSettings[ref.id] = localPanelSettings;
      state.parts.panelSettings[workspaceRef.id] = workspacePanelSettings;
      return PanelSettingsActions.setLocalAndWorkspacePanelSettings(
        ref,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(PanelSettingsActions.updateLocalAndWorkspacePanelSettings): {
      const {
        ref,
        workspaceRef,
        localPanelSettingsUpdate,
        workspacePanelSettingsUpdate,
      } = action.payload;
      const prevLocalSettings = cloneDeep(state.parts.panelSettings[ref.id]);
      const prevWorkspaceSettings = cloneDeep(
        state.parts.panelSettings[workspaceRef.id]
      );
      Object.assign(
        state.parts.panelSettings[ref.id],
        localPanelSettingsUpdate
      );
      Object.assign(
        state.parts.panelSettings[workspaceRef.id],
        workspacePanelSettingsUpdate
      );
      return PanelSettingsActions.setLocalAndWorkspacePanelSettings(
        ref,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(PanelSettingsActions.setAllLocalAndWorkspacePanelSettings): {
      const {refs, workspaceRef, localPanelSettings, workspacePanelSettings} =
        action.payload;
      const prevLocalSettings: Settings[] = [];
      refs.forEach((localRef, index) => {
        prevLocalSettings.push(
          cloneDeep(state.parts.panelSettings[localRef.id])
        );
        state.parts.panelSettings[localRef.id] = localPanelSettings[index];
      });
      const prevWorkspaceSettings = cloneDeep(
        state.parts.panelSettings[workspaceRef.id]
      );

      state.parts.panelSettings[workspaceRef.id] = workspacePanelSettings;
      return PanelSettingsActions.setAllLocalAndWorkspacePanelSettings(
        refs,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(
      PanelSettingsActions.updateAllLocalAndWorkspacePanelSettings
    ): {
      const {
        refs,
        workspaceRef,
        localPanelSettingsUpdate,
        workspacePanelSettingsUpdate,
      } = action.payload;
      const prevLocalSettings: Settings[] = [];
      refs.forEach(localRef => {
        prevLocalSettings.push(
          cloneDeep(state.parts.panelSettings[localRef.id])
        );
        Object.assign(
          state.parts.panelSettings[localRef.id],
          localPanelSettingsUpdate
        );
      });
      const prevWorkspaceSettings = cloneDeep(
        state.parts.panelSettings[workspaceRef.id]
      );
      Object.assign(
        state.parts.panelSettings[workspaceRef.id],
        workspacePanelSettingsUpdate
      );
      return PanelSettingsActions.setAllLocalAndWorkspacePanelSettings(
        refs,
        workspaceRef,
        prevLocalSettings,
        prevWorkspaceSettings
      );
    }
    case getType(RunSetActions.set): {
      const {ref, runSetNorm} = action.payload;
      const prev = cloneDeep(state.parts.runSet[ref.id]);
      state.parts.runSet[ref.id] = runSetNorm;
      return RunSetActions.set(action.payload.ref, prev);
    }
    case getType(SortActions.set): {
      const prevSort = cloneDeep(state.parts.sort[action.payload.ref.id]);
      state.parts.sort[action.payload.ref.id] = action.payload.sort;
      return SortActions.set(action.payload.ref, prevSort);
    }
    case getType(FilterActions.set): {
      const {ref, filters} = action.payload;
      const prevFilters = cloneDeep(state.parts.filters[ref.id]);
      state.parts.filters[ref.id] = filters;
      return FilterActions.set(action.payload.ref, prevFilters);
    }
    case getType(FilterActions.selectionsToFilters): {
      const {ref, selections, axes} = action.payload;
      const prevFilters = state.parts.filters[ref.id];
      const newFilters: Array<FilterTypes.Filter<Key>> = [];
      let existingFilters = cloneDeep(prevFilters);
      // If axes aren't specified, create filters for all selections
      (axes || Object.keys(selections)).forEach(axis => {
        const axisSelect = selections[axis];
        const key = keyFromString(axis);
        ['high', 'low'].forEach(boundary => {
          const value = axisSelect[boundary as 'high' | 'low'];
          if (key != null && value != null) {
            const existingFilterIndex =
              existingFilters.filters[0].filters.findIndex(
                f =>
                  Filter.isIndividual<Key>(f) &&
                  isEqual(key, f.key) &&
                  f.op === (boundary === 'low' ? '>=' : '<=')
              );
            // If we already have a filter for this bound, remove it
            if (existingFilterIndex > -1) {
              existingFilters = Filter.Update.groupRemove(
                existingFilters,
                [0],
                existingFilterIndex
              );
            }
            // Add the new filter
            newFilters.push({
              key,
              op: boundary === 'low' ? '>=' : '<=',
              value,
            });
          }
        });
        // Categorical variables (string/boolean columns)
        if (
          key != null &&
          axisSelect.match != null &&
          axisSelect.match.length > 0
        ) {
          newFilters.push({
            key,
            op: 'IN',
            value: axisSelect.match,
          });
        }
      });
      if (newFilters.length > 0) {
        state.parts.filters[ref.id] = Filter.Update.groupPush(
          existingFilters,
          [0],
          newFilters
        );
      }
      return FilterActions.set(ref, prevFilters);
    }
    case getType(CustomRunColorsActions.setCustomRunColor): {
      const {ref, id, color} = action.payload;
      const prevColor = state.parts[ref.type][ref.id][id];
      state.parts[ref.type][ref.id][id] = color;
      return CustomRunColorsActions.setCustomRunColor(ref, id, prevColor);
    }
    case getType(GroupSelectionsActions.setGrouping): {
      const ref = action.payload.ref;
      const prevGroupSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const newGroupSelections = {
        ...SM.setGrouping(prevGroupSelections, action.payload.grouping),
        expandedRowAddresses: [],
      };

      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.toggleExpandedRowAddress): {
      const ref = action.payload.ref;
      const prevGroupSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const newGroupSelections = SM.toggleExpandedRowAddress(
        prevGroupSelections,
        action.payload.rowAddress
      );
      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.addBound): {
      const {ref, bound} = action.payload;
      const prevGroupSelections = state.parts[ref.type][ref.id];
      const newGroupSelections = SM.addBound(prevGroupSelections, bound);
      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.selectAll): {
      const ref = action.payload.ref;
      const prevGroupSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const newGroupSelections = SM.selectAll(prevGroupSelections);
      state.parts[ref.type][ref.id] = newGroupSelections;
      // If the user has chosen to visualize all, ensure the runSet is enabled.
      // Iterate through all runsets to find the one that contains this groupSelection
      // ref.
      for (const runSet of Object.values(state.parts.runSet)) {
        if (isEqual(runSet.groupSelectionsRef, ref)) {
          runSet.enabled = true;
        }
      }
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActions.selectNone): {
      const ref = action.payload.ref;
      const prevGroupSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const newGroupSelections = SM.selectNone(prevGroupSelections);
      state.parts[ref.type][ref.id] = newGroupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(GroupSelectionsActionsInternal.setGroupSelections): {
      const ref = action.payload.ref;
      const prevGroupSelections = cloneDeep(state.parts[ref.type][ref.id]);
      state.parts[ref.type][ref.id] = action.payload.groupSelections;
      return GroupSelectionsActionsInternal.setGroupSelections(
        ref,
        prevGroupSelections
      );
    }
    case getType(TempSelectionsActions.selectAllVisible): {
      const {ref, groupSelectionsRef} = action.payload;
      const groupSelections = cloneDeep(
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id]
      );
      const prevTempSelections = cloneDeep(state.parts[ref.type][ref.id]);

      state.parts[ref.type][ref.id] = groupSelections.selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectNone): {
      const {ref, groupSelectionsRef} = action.payload;
      const groupSelections = cloneDeep(
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id]
      );
      const prevTempSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] =
        SM.selectNone(tempGroupSelection).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectToggle): {
      const {ref, groupSelectionsRef, run, depth} = action.payload;
      const groupSelections = cloneDeep(
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id]
      );
      const prevTempSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] = SM.toggleSelection(
        tempGroupSelection,
        run,
        depth
      ).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectSome): {
      const {ref, groupSelectionsRef, runs} = action.payload;
      const groupSelections = cloneDeep(
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id]
      );
      const prevTempSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] = SM.selectSome(
        tempGroupSelection,
        runs
      ).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActions.selectAll): {
      const {ref, groupSelectionsRef} = action.payload;
      const groupSelections = cloneDeep(
        state.parts[groupSelectionsRef.type][groupSelectionsRef.id]
      );
      const prevTempSelections = cloneDeep(state.parts[ref.type][ref.id]);
      const tempGroupSelection = {
        selections: prevTempSelections,
        grouping: groupSelections.grouping,
        expandedRowAddresses: groupSelections.expandedRowAddresses,
      };

      state.parts[ref.type][ref.id] =
        SM.selectAll(tempGroupSelection).selections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(TempSelectionsActionsInternal.setTempSelections): {
      const {ref, tempSelections} = action.payload;
      const prevTempSelections = state.parts[ref.type][ref.id];

      state.parts[ref.type][ref.id] = tempSelections;

      return TempSelectionsActionsInternal.setTempSelections(
        ref,
        prevTempSelections
      );
    }
    case getType(PanelActions.setConfigs): {
      const {refs, configs} = action.payload;
      const prevPanelConfigs = [];
      for (let i = 0; i < refs.length; i++) {
        prevPanelConfigs.push(cloneDeep(state.parts.panel[refs[i].id].config));
        state.parts.panel[refs[i].id].config = configs[i];
      }
      return PanelActions.setConfigs(refs, prevPanelConfigs);
    }
    case getType(PanelActions.updateConfigs): {
      const {refs, configUpdate} = action.payload;
      const prevPanelConfigs = [];
      for (const ref of refs) {
        prevPanelConfigs.push(cloneDeep(state.parts.panel[ref.id].config));
        Object.assign(state.parts.panel[ref.id].config, configUpdate);
      }
      return PanelActions.setConfigs(action.payload.refs, prevPanelConfigs);
    }
    case getType(PanelBankConfigActions.addSection): {
      // sectionRef is the existing section that you're inserting before or after
      const {ref, sectionRef, options} = action.payload;
      const newSectionRef =
        PanelBankConfigActionsInternal.addPanelBankSectionInternal(
          state,
          ref,
          sectionRef,
          options || {}
        );
      return PanelBankConfigActions.deleteSection(ref, newSectionRef);
    }
    case getType(PanelBankConfigActions.deleteSection): {
      // sectionRef is the section you're deleting
      const {ref, sectionRef, workspaceSettingsRef} = action.payload;
      const undoAction =
        PanelBankConfigActionsInternal.deletePanelBankSectionInternal(
          state,
          ref,
          sectionRef,
          workspaceSettingsRef
        );
      return undoAction;
    }
    case getType(PanelBankConfigActionsInternal.putSection): {
      // This internal action is the inverse of deleteSection.
      const {
        ref,
        sectionRef,
        sectionNorm,
        prevIndex,
        deletePanelsResult,
        localPanelSettings,
        localPanelSettingsRef,
        workspaceSettingsRef,
      } = action.payload;
      // Add section ref back to config.
      state.parts[ref.type][ref.id].sectionRefs.splice(
        prevIndex,
        0,
        sectionRef
      );
      // Put panel settings back in
      state.parts[localPanelSettingsRef.type][localPanelSettingsRef.id] =
        localPanelSettings;
      // Put normalized section back in parts.
      state.parts[sectionRef.type][sectionRef.id] = sectionNorm;
      // Put removed panels back
      PanelUtils.undoDeletePanels(state, deletePanelsResult, ref);
      return PanelBankConfigActions.deleteSection(
        ref,
        sectionRef,
        workspaceSettingsRef
      );
    }
    case getType(PanelBankConfigActions.moveSectionBefore): {
      const {ref, moveSectionRef, beforeSectionRef} = action.payload;

      // the section we are moving before
      const isBeforeSectionPinned =
        beforeSectionRef != null &&
        state.parts[beforeSectionRef.type][beforeSectionRef.id].pinned;

      state.parts[moveSectionRef.type][moveSectionRef.id].pinned =
        isBeforeSectionPinned;

      const moveSectionIndex = state.parts[ref.type][
        ref.id
      ].sectionRefs.findIndex(sr => sr.id === moveSectionRef.id);
      // Used to undo the action
      const nextSectionRef =
        state.parts[ref.type][ref.id].sectionRefs[moveSectionIndex + 1];
      // Remove the section
      state.parts[ref.type][ref.id].sectionRefs.splice(moveSectionIndex, 1);
      if (beforeSectionRef == null) {
        // Moving to the last position in the array
        state.parts[ref.type][ref.id].sectionRefs.splice(
          state.parts[ref.type][ref.id].sectionRefs.length - 1, // (-1 accounts for the 'Hidden Panels' section)
          0,
          moveSectionRef
        );
      } else {
        const beforeSectionIndex = state.parts[ref.type][
          ref.id
        ].sectionRefs.findIndex(sr => sr.id === beforeSectionRef.id);
        // // TODO(views): is this right? how do you cancel an action?
        // if (moveSectionIndex === beforeSectionIndex - 1) {
        //   return
        // }
        // Re-add it in the new index
        state.parts[ref.type][ref.id].sectionRefs.splice(
          beforeSectionIndex,
          0,
          moveSectionRef
        );
      }
      return PanelBankConfigActions.moveSectionBefore(
        ref,
        moveSectionRef,
        nextSectionRef
      );
    }
    // this is a separate action from updateSettings because named workspaces'
    // readonly mode for published workspaces will allow searching, and the
    // action name helps differentiate the two scenarios.
    case getType(PanelBankConfigActions.setCurrentPanelSearch): {
      const {ref, searchQuery} = action.payload;
      const prevSettings = cloneDeep(state.parts[ref.type][ref.id].settings);
      Object.assign(state.parts[ref.type][ref.id].settings, {
        searchQuery,
        // if search is cleared, clear temporary section open/close states too
        searchSectionsOpen: !searchQuery
          ? undefined
          : prevSettings.searchSectionsOpen,
      });
      return PanelBankConfigActions.updateSettings(ref, prevSettings);
    }
    case getType(PanelBankConfigActions.pinSection): {
      const {ref, sectionRef} = action.payload;

      const prev = cloneDeep(state.parts[ref.type][ref.id].sectionRefs);

      const isPinned = state.parts[sectionRef.type][sectionRef.id].pinned;

      const next: PanelBankSectionConfigTypes.Ref[] = [];

      // all pinned sections excluding the one we're pinning
      const pinnedWithoutCurrRef = prev.filter(sRef => {
        const section = state.parts[sRef.type][sRef.id];
        return section.pinned && sRef.id !== sectionRef.id;
      });

      // all unpinned sections excluding the one we're pinning
      const unPinnedWithoutCurrRef = prev.filter(sRef => {
        const section = state.parts[sRef.type][sRef.id];
        return !section.pinned && sRef.id !== sectionRef.id;
      });

      /*
       * if the section is getting unpinned, it should be placed after the last pinned section
       * if the section is getting pinned, it should be placed before the first unpinned section
       */
      if (!isPinned) {
        next.push(sectionRef);
        next.push(...pinnedWithoutCurrRef);
      } else {
        next.push(...pinnedWithoutCurrRef);
        next.push(sectionRef);
      }

      next.push(...unPinnedWithoutCurrRef);

      state.parts[ref.type][ref.id].sectionRefs = next;

      state.parts[sectionRef.type][sectionRef.id].pinned = !isPinned;

      return PanelBankConfigActions.pinSection(ref, sectionRef);
    }
    case getType(PanelBankConfigActions.updateSettingsAndSortPanels): {
      const {
        ref,
        args: {panelBankSettings, sortAllSections},
      } = action.payload;
      // update settings
      const prev = cloneDeep(state.parts[ref.type][ref.id].settings);
      Object.assign(state.parts[ref.type][ref.id].settings, panelBankSettings);
      const sectionRefs = cloneDeep(state.parts[ref.type][ref.id].sectionRefs);

      const sectionRefsToSort = sortAllSections
        ? sectionRefs
        : sectionRefs.filter(
            sectionRef =>
              state.parts[sectionRef.type][sectionRef.id].sorted !==
              SectionPanelSorting.Manual
          );
      // sort panels
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      sectionRefsToSort.forEach(sectionRef => {
        const panelRefs = state.parts[sectionRef.type][sectionRef.id].panelRefs;
        prevPanelRefs.push(cloneDeep(panelRefs));
        PanelBankSectionConfigActionsInternal.sortPanelsInternal(
          state,
          sectionRef,
          panelRefs
        );
      });
      return PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels(
        ref,
        prev,
        sectionRefsToSort,
        prevPanelRefs
      );
    }
    case getType(
      PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels
    ): {
      const {ref, panelBankSettings, sectionRefs, panelRefs} = action.payload;
      const prev = cloneDeep(state.parts[ref.type][ref.id].settings);
      Object.assign(state.parts[ref.type][ref.id].settings, panelBankSettings);
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      sectionRefs.forEach((sectionRef, index) => {
        prevPanelRefs.push(
          cloneDeep(state.parts[sectionRef.type][sectionRef.id].panelRefs)
        );
        state.parts[sectionRef.type][sectionRef.id].panelRefs =
          panelRefs[index];
      });
      return PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels(
        ref,
        prev,
        sectionRefs,
        prevPanelRefs
      );
    }
    // Only used for development (to stub undo actions)
    case getType(PanelBankConfigActions.noOp): {
      const {ref} = action.payload;
      return PanelBankConfigActions.noOp(ref);
    }
    case getType(PanelBankConfigActions.addPanelsBySpecUndo): {
      const {ref, newPanelRefs, newSectionRefs, specs} = action.payload;
      PanelUtils.deletePanels(state, newPanelRefs, undefined, ref);
      newSectionRefs.forEach(sectionRef => {
        PanelBankConfigActionsInternal.deletePanelBankSectionInternal(
          state,
          ref,
          sectionRef
        );
      });
      return PanelBankConfigActions.addPanelsBySpec(ref, specs);
    }
    case getType(PanelActions.deletePanels): {
      const {panelRefs, sectionRef, panelBankConfigRef, workspaceSettingsRef} =
        action.payload;
      const deletePanelsResult = PanelUtils.deletePanels(
        state,
        panelRefs,
        sectionRef,
        panelBankConfigRef,
        workspaceSettingsRef
      );
      return PanelActions.undoDeletePanels(
        deletePanelsResult,
        sectionRef,
        panelBankConfigRef,
        workspaceSettingsRef
      );
    }
    case getType(PanelActions.undoDeletePanels): {
      const {
        deletePanelsResult,
        sectionRef,
        panelBankConfigRef,
        workspaceSettingsRef,
      } = action.payload;
      const restoredPanelRefs = PanelUtils.undoDeletePanels(
        state,
        deletePanelsResult,
        panelBankConfigRef
      );
      return PanelActions.deletePanels(
        restoredPanelRefs,
        sectionRef,
        panelBankConfigRef,
        workspaceSettingsRef
      );
    }
    case getType(PanelBankSectionConfigActions.duplicatePanel): {
      const {ref, panelRef} = action.payload;
      const panel = Normalize.denormalize(state.parts, panelRef);
      const panelIndex = state.parts[ref.type][ref.id].panelRefs.findIndex(
        pRef => pRef.id === panelRef.id
      );
      const clonedPanel = {
        ...cloneDeep(panel),
        __id__: ID(),
      };
      const newPanelRef = Normalize.addObj(
        state.parts,
        'panel',
        ref.viewID,
        clonedPanel
      );
      state.parts[ref.type][ref.id].panelRefs.splice(
        panelIndex,
        0,
        newPanelRef
      );
      return PanelBankSectionConfigActions.deletePanel(ref, newPanelRef);
    }
    case getType(PanelBankSectionConfigActions.deletePanel): {
      const {ref, panelRef, panelBankConfigRef, workspaceSettingsRef} =
        action.payload;
      const deletePanelsResult = PanelUtils.deletePanels(
        state,
        [panelRef],
        ref,
        panelBankConfigRef,
        workspaceSettingsRef
      );
      return PanelActions.undoDeletePanels(
        deletePanelsResult,
        ref,
        panelBankConfigRef,
        workspaceSettingsRef
      );
    }
    // Create a new section and move a panel to it in one shot
    case getType(PanelBankConfigActions.movePanelToNewSection): {
      const {ref, args} = action.payload;
      const {fromSectionRef, panelRef, newSectionName} = args;

      const newSectionRef =
        PanelBankConfigActionsInternal.addPanelBankSectionInternal(
          state,
          ref,
          fromSectionRef,
          {newSectionName}
        );
      const panelRefs =
        state.parts[fromSectionRef.type][fromSectionRef.id].panelRefs;
      const fromIndex = panelRefs.findIndex(pRef => pRef.id === panelRef.id);

      PanelBankConfigActionsInternal.movePanelInternal(state, {
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef: newSectionRef,
        toIndex: 0,
      });

      return PanelBankConfigActionsInternal.undoMovePanelToNewSection(
        ref,
        panelRef,
        newSectionRef,
        fromSectionRef,
        fromIndex,
        newSectionName
      );
    }

    case getType(PanelBankConfigActionsInternal.undoMovePanelToNewSection): {
      const {
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
        newSectionName,
      } = action.payload;
      PanelBankConfigActionsInternal.movePanelInternal(state, {
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
      });
      PanelBankConfigActionsInternal.deletePanelBankSectionInternal(
        state,
        ref,
        fromSectionRef
      );
      return PanelBankConfigActions.movePanelToNewSection(ref, {
        panelRef,
        fromSectionRef: toSectionRef,
        newSectionName,
      });
    }
    case getType(PanelBankConfigActions.movePanel): {
      const {ref, panelRef, fromSectionRef, toSectionRef, inactivePanelRefIDs} =
        action.payload;
      const panelRefs =
        state.parts[fromSectionRef.type][fromSectionRef.id].panelRefs;
      // If toIndex is not specified, add panel to the end of the list
      const toIndex =
        action.payload.toIndex == null
          ? state.parts[toSectionRef.type][toSectionRef.id].panelRefs.length
          : action.payload.toIndex;
      // The panel's index in the fromSection
      const fromIndex = panelRefs.findIndex(pRef => pRef.id === panelRef.id);

      PanelBankConfigActionsInternal.movePanelInternal(state, {
        ref,
        fromSectionRef,
        panelRef,
        toSectionRef,
        toIndex,
        inactivePanelRefIDs,
      });
      return PanelBankConfigActions.movePanel(
        ref,
        panelRef,
        toSectionRef,
        fromSectionRef,
        fromIndex
      );
    }
    case getType(PanelBankSectionConfigActions.toggleType): {
      const {ref} = action.payload;
      const panelRefs = state.parts[ref.type][ref.id].panelRefs;
      state.parts[ref.type][ref.id].type =
        state.parts[ref.type][ref.id].type === 'grid' ? 'flow' : 'grid';
      // If we're switching to grid type, add layout to any panels that don't already have it
      if (state.parts[ref.type][ref.id].type === 'grid') {
        const panels = Normalize.denormalize(state.parts, ref).panels;
        panels.forEach((panel, i) => {
          if (panel.layout == null) {
            const panelRef = panelRefs[i];
            state.parts[panelRef.type][panelRef.id].layout = {
              ...findNextPanelLoc(
                Normalize.denormalize(state.parts, ref)
                  .panels.map(p => p.layout)
                  .filter(l => l),
                GRID_COLUMN_COUNT,
                GRID_ITEM_DEFAULT_WIDTH
              ),
              w: GRID_ITEM_DEFAULT_WIDTH,
              h: GRID_ITEM_DEFAULT_HEIGHT,
            };
          }
        });
      }
      return PanelBankSectionConfigActions.toggleType(ref);
    }
    case getType(PanelBankSectionConfigActions.updateName): {
      const {ref, newName} = action.payload;
      const prev = cloneDeep(state.parts[ref.type][ref.id].name);
      state.parts[ref.type][ref.id].name = newName;
      return PanelBankSectionConfigActions.updateName(ref, prev);
    }
    case getType(PanelBankSectionConfigActions.updateFlowConfig): {
      const {ref, newFlowConfig} = action.payload;
      const prev = cloneDeep(state.parts[ref.type][ref.id].flowConfig);
      state.parts[ref.type][ref.id].flowConfig = {
        ...state.parts[ref.type][ref.id].flowConfig,
        ...newFlowConfig,
      };
      return PanelBankSectionConfigActions.updateFlowConfig(ref, prev);
    }
    case getType(PanelBankSectionConfigActions.setGridLayout): {
      const {ref, newGridLayout} = action.payload;
      const prevGridLayout = Normalize.denormalize(state.parts, ref).panels.map(
        (p, i) => ({
          ...p.layout,
          id: state.parts[ref.type][ref.id].panelRefs[i].id,
        })
      );
      const panelRefs = state.parts[ref.type][ref.id].panelRefs;
      panelRefs.forEach(panelRef => {
        const newLayoutIndex = newGridLayout.findIndex(
          l => l.id === panelRef.id
        );
        if (newLayoutIndex > -1) {
          const l = newGridLayout[newLayoutIndex];
          state.parts.panel[panelRef.id] = {
            ...state.parts.panel[panelRef.id],
            layout: {
              x: l.x,
              y: l.y,
              w: l.w,
              h: l.h,
            },
          };
        }
      });

      return PanelBankSectionConfigActions.setGridLayout(ref, prevGridLayout);
    }
    // Sorts panels within a section
    case getType(PanelBankSectionConfigActions.sortPanels): {
      const sectionRefs = action.payload;
      const prevSorting: SectionPanelSorting[] = [];
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      sectionRefs.forEach(sectionRef => {
        const panelRefs = state.parts[sectionRef.type][sectionRef.id].panelRefs;
        prevPanelRefs.push(panelRefs);
        prevSorting.push(state.parts[sectionRef.type][sectionRef.id].sorted);
        PanelBankSectionConfigActionsInternal.sortPanelsInternal(
          state,
          sectionRef,
          panelRefs
        );
      });
      return PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting(
        sectionRefs,
        prevPanelRefs,
        prevSorting
      );
    }
    case getType(
      PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting
    ): {
      const {refs, orderedPanelRefs, sectionSortings} = action.payload;
      const prevPanelRefs: PanelTypes.Ref[][] = [];
      const prevSorting: SectionPanelSorting[] = [];
      refs.forEach((ref, index) => {
        prevPanelRefs.push(state.parts[ref.type][ref.id].panelRefs);
        prevSorting.push(state.parts[ref.type][ref.id].sorted);

        state.parts[ref.type][ref.id].sorted = sectionSortings[index];
        state.parts[ref.type][ref.id].panelRefs = orderedPanelRefs[index];
      });

      return PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting(
        refs,
        prevPanelRefs,
        prevSorting
      );
    }
    case getType(PanelBankSectionConfigActions.insertUpdatedPanel): {
      const {ref, fromPanelRef, panelRef} = action.payload;
      const originalState = original(state) as ViewReducerState;
      const whole = Normalize.denormalize(originalState.parts, fromPanelRef);
      const oldPanel = Normalize.denormalize(originalState.parts, panelRef);
      const normalizedSectionConfig: PanelBankSectionConfigNormalized =
        cloneDeep(state.parts[ref.type][ref.id]);
      const addedRef = Normalize.addObj(
        state.parts,
        fromPanelRef.type,
        panelRef.viewID,
        whole
      );
      if (fromPanelRef.type !== addedRef.type) {
        throw new Error('invalid action');
      }
      if (normalizedSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
        movePanelAlphabeticallyInSection(
          state,
          normalizedSectionConfig,
          panelRef,
          whole
        );
      }
      replacePart(state, panelRef, addedRef);

      return PanelBankSectionConfigActions.undoInsertUpdatedPanel(
        ref,
        oldPanel,
        panelRef
      );
    }
    case getType(PanelBankSectionConfigActions.undoInsertUpdatedPanel): {
      const {ref, panel, panelRef} = action.payload;
      const oldPanel = Normalize.denormalize(state.parts, panelRef);
      const normalizedSectionConfig: PanelBankSectionConfigNormalized =
        state.parts[ref.type][ref.id];
      const addedRef = Normalize.addObj(
        state.parts,
        panelRef.type,
        panelRef.viewID,
        panel
      );
      if (normalizedSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
        movePanelAlphabeticallyInSection(
          state,
          normalizedSectionConfig,
          panelRef,
          panel
        );
      }
      replacePart(state, panelRef, addedRef);

      return PanelBankSectionConfigActions.undoInsertUpdatedPanel(
        ref,
        oldPanel,
        panelRef
      );
    }
    case getType(ReportActions.setWidth): {
      const {ref, width} = action.payload;
      const prevWidth = state.parts[ref.type][ref.id].width;
      state.parts[ref.type][ref.id].width = width;
      return ReportActions.setWidth(ref, prevWidth);
    }
    case getType(ReportActions.setSpecVersion): {
      const {ref, specVersion} = action.payload;
      const prevVersion =
        state.parts[ref.type][ref.id].version || ReportSpecVersion.V0;
      state.parts[ref.type][ref.id].version = specVersion;
      return ReportActions.setSpecVersion(ref, prevVersion);
    }
    case getType(ReportActions.addAuthor): {
      const {ref, author} = action.payload;
      const prevAuthors = state.parts[ref.type][ref.id].authors ?? [];
      if (!prevAuthors.some(a => a.username === author.username)) {
        state.parts[ref.type][ref.id].authors = [...prevAuthors, author];
      }
      return ReportActions.removeAuthor(ref, author);
    }
    case getType(ReportActions.removeAuthor): {
      const {ref, author} = action.payload;
      const prevAuthors = state.parts[ref.type][ref.id].authors ?? [];
      const newAuthors = prevAuthors.filter(
        a => a.username !== author.username
      );
      state.parts[ref.type][ref.id].authors = newAuthors;
      return ReportActions.addAuthor(ref, author);
    }
    case getType(ReportActions.setBlocks): {
      const {ref, blocks} = action.payload;
      const prevBlocks = cloneDeep(state.parts[ref.type][ref.id].blocks);
      state.parts[ref.type][ref.id].blocks = blocks;
      return ReportActions.setBlocks(ref, prevBlocks);
    }
    case getType(Actions.rename): {
      const {ref, name} = action.payload;
      const prevName = state.views[ref.id].displayName;
      state.views[ref.id].displayName = name;
      return Actions.rename(ref, prevName);
    }
    case getType(Actions.setDescription): {
      const {ref, description} = action.payload;
      const prevDescription = state.views[ref.id].description;
      state.views[ref.id].description = description;
      return Actions.setDescription(ref, prevDescription);
    }
    case getType(Actions.setPreviewUrl): {
      const {ref, previewUrl} = action.payload;
      const prevPreviewUrl = state.views[ref.id].previewUrl;
      state.views[ref.id].previewUrl = previewUrl;
      return Actions.setPreviewUrl(ref, prevPreviewUrl);
    }
    case getType(Actions.setCoverUrl): {
      const {ref, coverUrl} = action.payload;
      const prevCoverUrl = state.views[ref.id].coverUrl;
      state.views[ref.id].coverUrl = coverUrl;
      return Actions.setCoverUrl(ref, prevCoverUrl);
    }
  }
  throw new Error('Action not undoable');
}

// Should be called from the main reducer for all undoable actions processed with immer
export function applyUndoableActionImmer(
  draft: ViewReducerState,
  action: ActionType
) {
  const inverseAction = applyAndMakeInverseActionImmer(draft, action);
  // Actions can be quite large - especially those that contain weave types. As
  // these build up, it results in the application's immer-based reducer to slow
  // down significantly. This is because it needs to walk the entire state tree.
  // Since undo actions are not meant to be mutated, but rather used to revert
  // the state, we can freeze them and avoid the performance hit.
  const frozenAction = freeze(inverseAction);
  draft.undoActions.push(frozenAction);
  draft.redoActions = [];
}

export function immerViewsReducer(state: ViewReducerState, action: ActionType) {
  return produce(state, (draft: ViewReducerState) => {
    switch (action.type) {
      case getType(Actions.loadMetadataListStarted): {
        draft.lists[action.payload.id] = {
          loading: true,
          query: action.payload.params,
          viewIds: [],
        };
        break;
      }

      case getType(ActionsInternal.loadMetadataListFinished): {
        const query = draft.lists[action.payload.id];
        if (!query) {
          // Happens if we unload before the metadatalist query finishes
          return;
        }
        query.loading = false;
        query.viewIds = action.payload.result.map(v => v.cid);
        for (const v of action.payload.result) {
          draft.views[v.cid] = {
            ...v,
            autoSave: false,
            saving: false,
            modified: false,
            loading: false,
            starLoading: false,
            panelCommentsEnabled: false,
          };
        }
        break;
      }

      case getType(ActionsInternal.clearReportViews): {
        // Clicking on share in a workspace panel will create a report, so
        // we only want to cleanup the old report views that aren't being used.
        for (const [reportCID, viewRef] of Object.entries(draft.views)) {
          if (viewRef.type === 'runs' || viewRef.type === 'runs/draft') {
            delete draft.views[reportCID];
          }
        }
        break;
      }

      case getType(ActionsInternal.loadStarted): {
        draft.loading = true;

        // Clear undo/redo state
        draft.redoActions = [];
        draft.undoActions = [];

        break;
      }
      case getType(ActionsInternal.unloadNoMatch): {
        draft.loading = false;

        // Clear undo/redo state
        draft.redoActions = [];
        draft.undoActions = [];

        break;
      }

      case getType(ActionsInternal.addNormalizedPanelGrid): {
        const {partsWithRefs} = action.payload;

        for (const {ref, part} of partsWithRefs) {
          draft.parts[ref.type as keyof StateType][ref.id] = part;
        }

        break;
      }

      case getType(ActionsInternal.removeNormalizedPanelGrid): {
        const {sectionRef} = action.payload;

        deleteParts(draft, sectionRef);

        break;
      }

      case getType(ActionsInternal.unloadMetadataList): {
        const viewListID = action.payload.id;
        delete draft.lists[viewListID];
        break;
      }

      case getType(ActionsInternal.unloadView): {
        const viewID = action.payload.id;
        delete draft.views[viewID];
        break;
      }

      case getType(ActionsInternal.updateViewName): {
        const {ref, name} = action.payload;
        const view = draft.views[ref.id];
        view.name = name;

        const date = new Date();
        view.updatedAt = date.toISOString();

        break;
      }

      case getType(ActionsInternal.updateViewSpec): {
        handleUpdateViewSpec(draft, action.payload.id, action.payload.spec, {
          wholeIsDefinitelyNew: true,
        });
        break;
      }

      case getType(ActionsInternal.deleteUndoRedoHistory): {
        draft.undoActions = [];
        draft.redoActions = [];
        break;
      }

      case getType(Actions.undo): {
        const undoableAction = draft.undoActions.pop();
        if (undoableAction != null) {
          const redoAction = applyAndMakeInverseActionImmer(
            draft,
            undoableAction
          );
          draft.redoActions.push(redoAction);
        }
        break;
      }

      case getType(Actions.redo): {
        const undoableAction = draft.redoActions.pop();
        if (undoableAction != null) {
          const undoAction = applyAndMakeInverseActionImmer(
            draft,
            undoableAction
          );
          draft.undoActions.push(undoAction);
        }
        break;
      }

      case getType(RunSetActions.visualizeAllIfNoneVisualized): {
        const {ref} = action.payload;
        const runSetPart = lookupPart(draft.parts, ref);
        const groupSelectionsPart = lookupPart(
          draft.parts,
          runSetPart.groupSelectionsRef
        );
        if (SM.isNoneSelected(groupSelectionsPart)) {
          SM.selectAllMutate(groupSelectionsPart);
        }
        break;
      }

      case getType(ActionsInternal.markModified): {
        draft.views[action.payload.id].modified = true;
        break;
      }

      case getType(ActionsInternal.saveStarted): {
        if (draft.views[action.payload.cid]) {
          draft.views[action.payload.cid].saving = true;
        } else {
          console.error(
            'saveStarted: undefined draft.views for payload id:',
            action?.payload?.cid
          );
        }
        break;
      }

      case getType(ActionsInternal.saveFailed): {
        // Note that the UI isn't well tested after we've
        // had a sync failure.
        const {cid} = action.payload;
        if (draft.views[cid]) {
          draft.views[cid].saving = false;
        } else {
          console.error(
            'saveFailed: undefined draft.views for payload id:',
            action?.payload?.cid
          );
        }
        break;
      }

      case getType(ActionsInternal.starViewStarted): {
        const {id} = action.payload;
        draft.views[id].starLoading = true;
        break;
      }

      case getType(ActionsInternal.starViewFinished): {
        const {id, starCount} = action.payload;
        draft.views[id].starLoading = false;
        draft.views[id].starCount = starCount;
        draft.views[id].starred = true;
        break;
      }

      case getType(ActionsInternal.unstarViewStarted): {
        const {id} = action.payload;
        draft.views[id].starLoading = true;
        break;
      }

      case getType(ActionsInternal.unstarViewFinished): {
        const {id, starCount} = action.payload;
        draft.views[id].starLoading = false;
        draft.views[id].starCount = starCount;
        draft.views[id].starred = false;
        break;
      }

      case getType(Actions.setLocked): {
        const {ref, locked} = action.payload;
        draft.views[ref.id].locked = locked;
        break;
      }

      case getType(Actions.addAccessToken): {
        const {ref, accessToken} = action.payload;
        const v = draft.views[ref.id];
        if (v.accessTokens == null) {
          v.accessTokens = [];
        }
        v.accessTokens.push(accessToken);
        break;
      }

      case getType(Actions.updateAccessToken): {
        const {ref, accessToken} = action.payload;
        const view = draft.views[ref.id];
        if (view.accessTokens == null) {
          view.accessTokens = [];
        }
        view.accessTokens = [
          ...view.accessTokens.filter(t => t.token !== accessToken.token),
          accessToken,
        ];
        break;
      }

      case getType(Actions.removeAccessToken): {
        const {ref, token} = action.payload;
        const v = draft.views[ref.id];
        if (v.accessTokens != null) {
          v.accessTokens = v.accessTokens.filter(at => at.token !== token);
        }
        break;
      }

      case getType(ReportActions.deleteDiscussionComment): {
        const {discussionThreadRef, discussionCommentRef} =
          action.payload.params;
        // Remove the comment from the thread
        const discussionThread =
          draft.parts[discussionThreadRef.type][discussionThreadRef.id];
        const commentIndex = _.findIndex(
          discussionThread.commentRefs,
          discussionCommentRef
        );
        if (commentIndex === -1) {
          throw new Error('invalid action');
        }
        discussionThread.commentRefs.splice(commentIndex, 1);

        // Delete the comment
        removeHistoryForObject(draft, discussionCommentRef);
        deleteParts(draft, discussionCommentRef);
        break;
      }

      case getType(ReportActions.deleteDiscussionThread): {
        const {ref, params} = action.payload;
        const {discussionThreadRef} = params;

        // Remove the thread from the report
        const report = draft.parts[ref.type][ref.id];
        const threadIndex = _.findIndex(
          report.discussionThreadRefs,
          discussionThreadRef
        );
        if (threadIndex === -1) {
          throw new Error('invalid action');
        }
        report.discussionThreadRefs.splice(threadIndex, 1);

        // Delete the thread
        removeHistoryForObject(draft, discussionThreadRef);
        deleteParts(draft, discussionThreadRef);
        break;
      }

      case getType(ReportActions.loadDiscussionThreads): {
        const {ref, response} = action.payload;
        const reportPart = draft.parts[ref.type][ref.id];
        const existingThreadRefs = reportPart.discussionThreadRefs;
        const newThreadRefs = [];
        let existingThreadI = 0;
        for (const responseThread of response.discussionThreads) {
          // if the discussion thread ref already exists, don't replace it with a new one
          const existingThreadRef = existingThreadRefs[existingThreadI];
          if (existingThreadRef != null) {
            const existingThreadPart =
              draft.parts['discussion-thread'][existingThreadRef.id];
            if (existingThreadPart.id === responseThread.id) {
              const existingCommentRefs = existingThreadPart.commentRefs;
              const newCommentRefs = [];
              let existingCommentI = 0;
              for (const responseComment of responseThread.comments) {
                const existingCommentRef =
                  existingCommentRefs[existingCommentI];
                if (existingCommentRef != null) {
                  const existingCommentPart =
                    draft.parts['discussion-comment'][existingCommentRef.id];
                  if (existingCommentPart.id === responseComment.id) {
                    draft.parts['discussion-comment'][existingCommentRef.id] =
                      responseComment;
                    newCommentRefs.push(existingCommentRef);
                    existingCommentI++;
                    continue;
                  }
                }
                newCommentRefs.push(
                  Normalize.addObj(
                    draft.parts,
                    'discussion-comment',
                    ref.viewID,
                    responseComment
                  )
                );
              }

              draft.parts['discussion-thread'][existingThreadRef.id] = {
                ..._.omit(responseThread, 'comments'),
                commentRefs: newCommentRefs,
              };
              newThreadRefs.push(existingThreadRef);
              existingThreadI++;
              continue;
            }
          }
          newThreadRefs.push(
            Normalize.addObj(
              draft.parts,
              'discussion-thread',
              ref.viewID,
              responseThread
            )
          );
        }
        // Associate the threads with the report
        reportPart.discussionThreadRefs = newThreadRefs;
        break;
      }

      case getType(ReportActions.addDiscussionComment): {
        const {ref, response} = action.payload;
        let {discussionThreadRef} = action.payload;
        let isNewThread = false;
        if (discussionThreadRef == null) {
          // creating a new thread
          isNewThread = true;
          discussionThreadRef = Normalize.addObj(
            draft.parts,
            'discussion-thread',
            ref.viewID,
            response.discussionThread
          );
        }
        const newCommentRef = Normalize.addObj(
          draft.parts,
          'discussion-comment',
          ref.viewID,
          response.discussionComment
        );
        const discussionThreadPart =
          draft.parts[discussionThreadRef.type][discussionThreadRef.id];
        // Add new comment to store
        discussionThreadPart.commentRefs.push(newCommentRef);
        // Add new thread to store
        if (isNewThread) {
          draft.parts[ref.type][ref.id].discussionThreadRefs.unshift(
            discussionThreadRef
          );
        }
        // Subscribe the user to comment alerts
        draft.views[ref.viewID].alertSubscription = response.alertSubscription;
        break;
      }

      case getType(DiscussionCommentActions.updateDiscussionComment): {
        const {ref, updatedComment} = action.payload;
        draft.parts[ref.type][ref.id] = updatedComment;
        break;
      }

      case getType(Actions.setCommentAlertSubscription): {
        const {ref, subscriptionID} = action.payload;
        draft.views[ref.id].alertSubscription =
          subscriptionID == null ? undefined : {id: subscriptionID};
        break;
      }

      case getType(Actions.setAutosave): {
        const {ref, autosave} = action.payload;
        draft.views[ref.id].autoSave = autosave;
        break;
      }

      case getType(Actions.hideWorkspaceBanner): {
        const {ref, bannerType, at} = action.payload;
        if (bannerType === 'cli-version-warning' && ref.type === 'run-view') {
          draft.parts[ref.type][ref.id].cliVersionWarningHiddenAt = at;
        } else if (bannerType === 'panel-auto-gen-info') {
          draft.parts[ref.type][ref.id].panelAutoGenInfoHiddenAt = at;
        }
        break;
      }

      case getType(Actions.noop):
      case getType(ActionsInternal.undoableUpdateViewSpec):
      case getType(SectionActions.setRunColor):
      case getType(SectionActions.removeRunSet):
      case getType(SectionActions.duplicateRunSet):
      case getType(SectionActions.reorderRunSet):
      case getType(SectionActions.insertRunSet):
      case getType(SectionActions.setActiveIndex):
      case getType(SectionActions.setHideRunSets):
      case getType(SectionActions.setOpen):
      case getType(SectionActions.setName):
      case getType(MarkdownBlockActions.setContent):
      case getType(MarkdownBlockActions.setCollapsed):
      case getType(PanelSettingsActions.set):
      case getType(PanelSettingsActions.update):
      case getType(PanelSettingsActions.setLocalAndWorkspacePanelSettings):
      case getType(PanelSettingsActions.updateLocalAndWorkspacePanelSettings):
      case getType(PanelSettingsActions.setAllLocalAndWorkspacePanelSettings):
      case getType(
        PanelSettingsActions.updateAllLocalAndWorkspacePanelSettings
      ):
      case getType(RunSetActions.set):
      case getType(SortActions.set):
      case getType(FilterActions.set):
      case getType(FilterActions.selectionsToFilters):
      case getType(CustomRunColorsActions.setCustomRunColor):
      case getType(GroupSelectionsActions.setGrouping):
      case getType(GroupSelectionsActions.toggleExpandedRowAddress):
      case getType(GroupSelectionsActions.addBound):
      case getType(GroupSelectionsActions.selectAll):
      case getType(GroupSelectionsActions.selectNone):
      case getType(GroupSelectionsActionsInternal.setGroupSelections):
      case getType(TempSelectionsActions.selectAllVisible):
      case getType(TempSelectionsActions.selectNone):
      case getType(TempSelectionsActions.selectToggle):
      case getType(TempSelectionsActions.selectSome):
      case getType(TempSelectionsActions.selectAll):
      case getType(TempSelectionsActionsInternal.setTempSelections):
      case getType(PanelActions.setConfigs):
      case getType(PanelActions.updateConfigs):
      case getType(PanelBankConfigActions.setCurrentPanelSearch):
      case getType(PanelBankConfigActions.updateSettingsAndSortPanels):
      case getType(PanelBankConfigActions.addSection):
      case getType(PanelBankConfigActions.deleteSection):
      case getType(PanelBankConfigActions.moveSectionBefore):
      case getType(PanelBankConfigActions.movePanel):
      case getType(PanelBankConfigActions.movePanelToNewSection):
      case getType(PanelBankConfigActionsInternal.undoMovePanelToNewSection):
      case getType(
        PanelBankConfigActionsInternal.undoUpdateSettingsAndSortPanels
      ):
      case getType(PanelBankConfigActions.noOp):
      case getType(PanelBankConfigActions.pinSection):
      case getType(PanelBankSectionConfigActions.sortPanels):
      case getType(
        PanelBankSectionConfigActions.setSectionPanelRefsAndUndoSortingSetting
      ):
      case getType(PanelBankSectionConfigActions.insertUpdatedPanel):
      case getType(PanelBankSectionConfigActions.deletePanel):
      case getType(PanelBankSectionConfigActions.duplicatePanel):
      case getType(PanelBankSectionConfigActions.toggleType):
      case getType(PanelBankSectionConfigActions.updateName):
      case getType(PanelBankSectionConfigActions.updateFlowConfig):
      case getType(PanelBankSectionConfigActions.setGridLayout):
      case getType(PanelBankSectionConfigActions.undoInsertUpdatedPanel):
      case getType(ReportActions.removeSection):
      case getType(ReportActions.setWidth):
      case getType(ReportActions.setSpecVersion):
      case getType(ReportActions.copySection):
      case getType(ReportActions.insertSection):
      case getType(ReportActions.moveSection):
      case getType(ReportActions.addAuthor):
      case getType(ReportActions.removeAuthor):
      case getType(ReportActions.setBlocks):
      case getType(Actions.rename):
      case getType(Actions.setPreviewUrl):
      case getType(Actions.setCoverUrl):
      case getType(Actions.setDescription): {
        applyUndoableActionImmer(draft, action);
        break;
      }
    }
  });
}
