import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import {Ref as CustomRunColorsViewRef} from '../../state/views/customRunColors/types';
import {usePart} from '../../state/views/hooks';
import * as PanelTypes from '../../state/views/panel/types';
import type {Ref as PanelBankConfigRef} from '../../state/views/panelBankConfig/types';
import type {Ref as PanelBankSectionConfigRef} from '../../state/views/panelBankSectionConfig/types';
import type {Ref as RunSetViewRef} from '../../state/views/runSet/types';
import type {Ref as WorkspaceSettingsRef} from '../../state/views/workspaceSettings/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {useDeepEqualValue} from '../../util/hooks';
import {LayedOutPanel} from '../../util/panelTypes';
import {PanelLoadingState} from '../PanelBank/PanelBankTiming';
import {InheritedSettings} from '../WorkspaceDrawer/Settings/types';
import {PanelPopupMode} from './types';

// TODO - as panels gets refactored some more, consider separating some of these out to a PanelBankContextProvider

interface PanelContextProps {
  customRunColorsRef: CustomRunColorsViewRef;
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigRef;
  panelBankSectionConfigRef: PanelBankSectionConfigRef;
  panelRef: PanelTypes.Ref;
  inheritedSettings?: InheritedSettings;
  runSetRefs: RunSetViewRef[];
  searchQuery?: string;
  loadingState?: PanelLoadingState;
  workspaceSettingsRef?: WorkspaceSettingsRef;
  panelPopupMode?: PanelPopupMode;
  setPanelPopupMode: Dispatch<SetStateAction<PanelPopupMode | undefined>>;
}

type PanelContextType = PanelContextProps & {
  panel: LayedOutPanel;
};

export const PanelContext = createContext<PanelContextType>({
  customRunColorsRef: {id: '', type: 'run-colors', viewID: ''},
  panelBankSectionConfigRef: {
    id: '',
    type: 'panel-bank-section-config',
    viewID: '',
  },
  panelRef: {
    id: '',
    type: 'panel',
    viewID: '',
  },
  panel: {} as LayedOutPanel,
  runSetRefs: [
    {
      id: '',
      type: 'runSet',
      viewID: '',
    },
  ],
  setPanelPopupMode: () => {},
});
PanelContext.displayName = 'PanelContext';

interface PanelContextProviderProps {
  children: ReactNode;
  customRunColorsRef: CustomRunColorsViewRef;
  historyKeyInfo?: RunHistoryKeyInfo; // Used in PanelRunsLinePlot
  isReadOnly?: boolean;
  panelBankConfigRef?: PanelBankConfigRef; // the panelbank config, used for PanelMover
  panelBankSectionConfigRef: PanelBankSectionConfigRef; // the panelbank section that contains this panel, used for PanelMover
  panelRef: PanelTypes.Ref;
  inheritedSettings?: InheritedSettings;
  runSetRefs: RunSetViewRef[];
  loadingState?: PanelLoadingState;
  /**
   * Panel component is used in both workspaces and reports.
   * Only workspaces has been migrated over to the new settings structure, so
   * this ref will be undefined in reports for now.
   */
  workspaceSettingsRef?: WorkspaceSettingsRef;
}

/**
 * Panel components are shared between workspaces and reports, so this context provider
 * acts as a bridge between the two. Not all workspace related context providers wrap
 * around report panel grid, so using certain context hooks will result in an error.
 * PanelContextProvider exists to circumvent this,
 */
export const PanelContextProvider: FC<PanelContextProviderProps> = ({
  children,
  customRunColorsRef,
  isReadOnly,
  panelBankConfigRef,
  panelBankSectionConfigRef,
  panelRef,
  inheritedSettings,
  runSetRefs,
  loadingState,
}) => {
  const panel = usePart(panelRef);
  const deepEqualPanel = useDeepEqualValue(panel);
  const [panelPopupMode, setPanelPopupMode] = useState<PanelPopupMode>();
  const state = useMemo(
    () => ({
      customRunColorsRef,
      isReadOnly,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      panelRef,
      panel: deepEqualPanel,
      inheritedSettings,
      runSetRefs,
      loadingState,
      panelPopupMode,
      setPanelPopupMode,
    }),
    [
      customRunColorsRef,
      isReadOnly,
      panelBankConfigRef,
      panelBankSectionConfigRef,
      panelRef,
      deepEqualPanel,
      inheritedSettings,
      runSetRefs,
      loadingState,
      panelPopupMode,
      setPanelPopupMode,
    ]
  );

  return (
    <PanelContext.Provider value={state}>{children} </PanelContext.Provider>
  );
};

export const usePanelContext = (): PanelContextType => {
  const value = useContext(PanelContext);

  if (value == null) {
    throw new Error(
      'usePanelContext must be used within a PanelContextProvider'
    );
  }

  return value;
};
