import React, {createContext, useContext, useMemo} from 'react';

import {Ref as PanelBankConfigRef} from '../../state/views/panelBankConfig/types';
import {Ref as WorkspaceSettingsRef} from '../../state/views/workspaceSettings/types';
import {useDetectChanges} from '../../util/react-help/detectReactPropChanges';
import {usePanelBankActions} from '../PanelBank/usePanelBankActions';

type WorkspaceRefsProviderProps = {
  panelBankConfigRef: PanelBankConfigRef;
  workspaceSettingsRef: WorkspaceSettingsRef;
  children: React.ReactNode;
};

type WorkspaceRefsProviderValue = {
  panelBankActions: ReturnType<typeof usePanelBankActions>;
  panelBankConfigRef: PanelBankConfigRef;
  workspaceSettingsRef: WorkspaceSettingsRef;
};

const WorkspaceRefsContext = createContext<
  WorkspaceRefsProviderValue | undefined
>(undefined);

export const WorkspaceRefsContextProvider = ({
  panelBankConfigRef,
  workspaceSettingsRef,
  children,
}: WorkspaceRefsProviderProps) => {
  const panelBankActions = usePanelBankActions(panelBankConfigRef);
  const value = useMemo(
    () => ({
      panelBankActions,
      panelBankConfigRef,
      workspaceSettingsRef,
    }),
    [panelBankActions, panelBankConfigRef, workspaceSettingsRef]
  );

  useDetectChanges('WorkspaceRefsContextProvider', {
    panelBankActions,
    panelBankConfigRef,
    workspaceSettingsRef,
  });

  return (
    <WorkspaceRefsContext.Provider value={value}>
      {children}
    </WorkspaceRefsContext.Provider>
  );
};

export const useWorkspaceRefsContext = () => {
  const value = useContext(WorkspaceRefsContext);

  if (value == null) {
    throw new Error(
      'useWorkspaceRefsContext must be used within a WorkspaceRefsContext'
    );
  }

  return value;
};
