import React, {createContext, useContext, useMemo} from 'react';

import {usePart, useViewAction} from '../../../state/views/hooks';
import * as PanelBankConfigActions from '../../../state/views/panelBankConfig/actions';
import * as WorkspaceSettingsActions from '../../../state/views/workspaceSettings/actions';
import {DispatchableAction} from '../../../types/redux';
import {useRampFlagPanelGenQuickAdd} from '../../../util/rampFeatureFlags';
import {useDetectChanges} from '../../../util/react-help/detectReactPropChanges';
import {SmoothingTypeValues} from '../../elements/SmoothingConfig';
import {useWorkspaceRefsContext} from '../../Workspace/WorkspaceRefsContext';
import {useCascadingPointAggregationMethod} from './getCascadingPointAggregation';
import {getLinePlotSettings} from './getLinePlotSettings';
import {useWorkspaceXAxisOptions} from './LinePlotXAxis';
import {
  DerivedPointVisualizationOption,
  LinePlotSettings,
  ShowMinMaxOnHover,
  TooltipNumberOfRunsOptions,
} from './types';
import {useUpdateShouldAutoGeneratePanels} from './useUpdateShouldAutoGeneratePanels';

// TODO (joyce) - change to Required<OrganizedSettings> when all settings
// are implemented in new UX
export type WorkspaceSettingsContextType = {
  // settings
  linePlot: {
    colorRunNames: boolean | undefined;
    displayFullRunName: boolean | undefined;
    highlightedCompanionRunOnly: boolean | undefined;
    ignoreOutliers: boolean | undefined;
    maxRuns: number | undefined;
    pointVisualizationMethod: DerivedPointVisualizationOption;
    showMinMaxOnHover: ShowMinMaxOnHover | undefined;
    smoothingType: SmoothingTypeValues | undefined;
    smoothingWeight: number | undefined;
    suppressLegends: boolean | undefined;
    tooltipNumberOfRuns: TooltipNumberOfRunsOptions | undefined;
    xAxis: string | undefined;
    xAxisMax: number | undefined;
    xAxisMin: number | undefined;
  };
  shouldAutoGeneratePanels: boolean;

  // updaters
  updateLinePlotWorkspaceSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  updateAllLinePlotSectionSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  updateShouldAutoGeneratePanels: (shouldEnable: boolean) => void;
};

export const WorkspaceSettingsContext = createContext<
  WorkspaceSettingsContextType | undefined
>(undefined);

type WorkspaceSettingsProps = {
  children: React.ReactNode;
};

/**
 * This context provider is used in conjuction with SectionSettingsContextProvider.
 * We use these two context providers to handle cascading settings logic.
 */
export const WorkspaceSettingsContextProvider = ({
  children,
}: WorkspaceSettingsProps) => {
  const {panelBankConfigRef, workspaceSettingsRef} = useWorkspaceRefsContext();
  const workspaceSettings = usePart(workspaceSettingsRef);

  const xAxisOptions = useWorkspaceXAxisOptions();

  const {
    colorRunNames,
    displayFullRunName,
    highlightedCompanionRunOnly,
    ignoreOutliers,
    maxRuns,
    smoothingType,
    smoothingWeight,
    suppressLegends,
    tooltipNumberOfRuns,
    xAxis,
    xAxisMax,
    xAxisMin,
  } = getLinePlotSettings(workspaceSettings);

  // This feature is still under development, so we can't directly use the value in workspaceSettings. We need to check for additional values.
  // Note: once feature is fully released on prod, move values up to `getLinePlotSettings`
  const {pointVisualizationMethod, showMinMaxOnHover} =
    useCascadingPointAggregationMethod(workspaceSettings?.linePlot);

  const updateLinePlotWorkspaceSettings = useViewAction(
    workspaceSettingsRef,
    WorkspaceSettingsActions.updateLinePlotWorkspaceSettings
  );

  const updateAllLinePlotSectionSettings = useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.updateAllLinePlotSectionSettings
  );

  const isPanelQuickAddEnabled = useRampFlagPanelGenQuickAdd();
  const shouldAutoGeneratePanels = isPanelQuickAddEnabled
    ? workspaceSettings?.shouldAutoGeneratePanels ?? true
    : true;
  const updateShouldAutoGeneratePanels =
    useUpdateShouldAutoGeneratePanels(workspaceSettingsRef);

  const value = useMemo(
    () => ({
      // settings
      linePlot: {
        colorRunNames,
        displayFullRunName,
        highlightedCompanionRunOnly,
        ignoreOutliers,
        maxRuns,
        pointVisualizationMethod,
        showMinMaxOnHover,
        smoothingType,
        smoothingWeight,
        suppressLegends,
        tooltipNumberOfRuns,
        xAxis,
        xAxisMax,
        xAxisMin,
      },
      shouldAutoGeneratePanels,

      // updaters
      updateLinePlotWorkspaceSettings,
      updateAllLinePlotSectionSettings,
      updateShouldAutoGeneratePanels,
    }),
    [
      colorRunNames,
      displayFullRunName,
      highlightedCompanionRunOnly,
      ignoreOutliers,
      maxRuns,
      pointVisualizationMethod,
      shouldAutoGeneratePanels,
      showMinMaxOnHover,
      smoothingType,
      smoothingWeight,
      suppressLegends,
      tooltipNumberOfRuns,
      updateAllLinePlotSectionSettings,
      updateLinePlotWorkspaceSettings,
      updateShouldAutoGeneratePanels,
      xAxis,
      xAxisMax,
      xAxisMin,
    ]
  );

  useDetectChanges('WorkspaceSettingsContextProvider', {
    ignoreOutliers,
    panelBankConfigRef,
    shouldAutoGeneratePanels,
    smoothingType,
    smoothingWeight,
    updateAllLinePlotSectionSettings,
    updateLinePlotWorkspaceSettings,
    updateShouldAutoGeneratePanels,
    workspaceSettingsRef,
    xAxis,
    xAxisMax,
    xAxisMin,
    xAxisOptions,
  });

  return (
    <WorkspaceSettingsContext.Provider value={value}>
      {children}
    </WorkspaceSettingsContext.Provider>
  );
};

export const useWorkspaceSettingsContext = (): WorkspaceSettingsContextType => {
  const value = useContext(WorkspaceSettingsContext);

  if (value == null) {
    throw new Error(
      'useWorkspaceSettingsContext must be used within a WorkspaceSettingsContextProvider'
    );
  }

  return value;
};
