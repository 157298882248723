import gql from 'graphql-tag';

import {runsTableFragment} from '../components/RunsTable';

export const ADD_USER_TO_ORGANIZATION = gql`
  mutation addUserToOrganization(
    $userName: String!
    $teams: [String!]
    $organizationId: ID!
    $clientMutationId: String
    $userOrgRole: String!
  ) {
    addUserToOrganization(
      input: {
        userName: $userName
        teams: $teams
        organizationId: $organizationId
        clientMutationId: $clientMutationId
        userOrgRole: $userOrgRole
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation removeUserFromOrganization(
    $userName: String!
    $organizationId: ID!
    $clientMutationId: String
  ) {
    removeUserFromOrganization(
      input: {
        userName: $userName
        organizationId: $organizationId
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const REMOVE_USER_FROM_ORGANIZATION_BY_ID = gql`
  mutation removeUserFromOrganizationById(
    $userId: ID!
    $organizationId: ID!
    $clientMutationId: String
  ) {
    removeUserFromOrganizationById(
      input: {
        userId: $userId
        organizationId: $organizationId
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $organizationID: ID!
    $flags: JSONString
    $name: String
  ) {
    updateOrganization(
      input: {organizationID: $organizationID, flags: $flags, name: $name}
    ) {
      success
      clientMutationId
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($organizationId: ID!, $clientMutationId: String) {
    deleteOrganization(
      input: {
        organizationId: $organizationId
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateOrganizationUser(
    $userName: String!
    $organizationId: ID!
    $userOrgRole: String!
    $clientMutationId: String
  ) {
    updateOrganizationUser(
      input: {
        userName: $userName
        organizationId: $organizationId
        userOrgRole: $userOrgRole
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const UPDATE_BILLING_USER = gql`
  mutation updateOrganizationBillingUser(
    $organizationId: ID!
    $newBillingUser: ID!
    $clientMutationId: String
  ) {
    updateOrganizationBillingUser(
      input: {
        organizationId: $organizationId
        newBillingUser: $newBillingUser
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const UPDATE_ORGANIZATION_SUBSCRIPTION = gql`
  mutation updateOrganizationSubscription(
    $organizationSubscriptionID: ID!
    $createStripeSubscription: Boolean!
    $planID: ID
    $seats: Int
    $privileges: JSONString
    $type: OrganizationSubscriptionType
    $status: OrganizationSubscriptionStatus
    $stripeSubscriptionId: String
    $expiresAt: DateTime
  ) {
    updateOrganizationSubscription(
      input: {
        organizationSubscriptionID: $organizationSubscriptionID
        planID: $planID
        seats: $seats
        privileges: $privileges
        type: $type
        status: $status
        stripeSubscriptionId: $stripeSubscriptionId
        expiresAt: $expiresAt
        createStripeSubscription: $createStripeSubscription
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const DELETE_ORGANIZATION_SUBSCRIPTION = gql`
  mutation deleteOrganizationSubscription($subscriptionID: ID!) {
    deleteOrganizationSubscription(input: {subscriptionID: $subscriptionID}) {
      success
      clientMutationId
    }
  }
`;

export const LINK_TEAM_TO_ORGANIZATION = gql`
  mutation linkTeamToOrganization(
    $entityName: String!
    $organizationID: ID!
    $clientMutationId: String
  ) {
    linkTeamToOrganization(
      input: {
        entityName: $entityName
        organizationID: $organizationID
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const ALL_ORGANIZATIONS = gql`
  query allOrganizations(
    $first: Int
    $cursor: String
    $queryOrg: String
    $querySubId: String
    $queryUser: String
    $queryTeam: String
  ) {
    organizations(
      first: $first
      after: $cursor
      queryOrg: $queryOrg
      querySubId: $querySubId
      queryUser: $queryUser
      queryTeam: $queryTeam
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          orgType
          flags
          billingUser {
            id
            username
            email
          }
          teams {
            id
            name
          }
          usedSeats
          stripeBillingInfo {
            stripeSubscriptionId
            status
            currentPeriodEnd
            cancelAtPeriodEnd
            paymentMethod {
              id
              cardType
              endingIn
            }
          }
          members {
            orgID
            id
            admin
            name
            username
            photoUrl
            teams {
              edges {
                node {
                  id
                  name
                }
              }
            }
            role
          }
          subscriptions {
            plan {
              id
              name
              planType
              displayName
            }
            seats
            id
            subscriptionType
            stripeSubscriptionId
            status
            expiresAt
            privileges
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_SUBSCRIPTION_INFO = gql`
  query OrganizationSubscriptions {
    viewer {
      id
      organizations {
        id
        name
        billingUser {
          id
          username
          email
        }
        flags
        usedSeats
        subscriptions {
          id
          subscriptionType
          status
          expiresAt
          privileges
          plan {
            id
            name
            planType
            billingInterval
          }
          seats
          isAutomaticUpgrade
          createdAt
          thresholdCrossedAt
          upgradedAt
          billingPeriodStart
          billingPeriodEnd
          expansionPacks {
            edges {
              node {
                id
                secondsLimit
                secondsRemaining
                expiresAt
              }
            }
          }
        }
        teams {
          id
          name
          computeHours
          oldComputeHours: computeHours(minDaysOld: 14)
        }
        members {
          orgID
          id
          username
          role
        }
        pendingInvites {
          id
        }
      }
    }
  }
`;

export const ORGANIZATION_STORAGE_INFO = gql`
  query OrganizationStorageInfo($organizationID: ID!) {
    organization(id: $organizationID) {
      id
      teams {
        id
        storageBytes(cached: true)
        referenceBytes(cached: true)
      }
    }
  }
`;

const ORGANIZATION_SUBSCRIPTION_BASIC_INFO_FRAGMENT = gql`
  fragment OrganizationSubscriptionBasicInfo on Organization {
    name
    orgType
    members {
      orgID
      id
    }
    subscriptions {
      id
      subscriptionType
      plan {
        id
        name
        planType
      }
    }
  }
`;

export const ORGANIZATION_SUBSCRIPTION_BASIC_INFO = gql`
  query OrganizationSubscriptionsBasicInfo {
    viewer {
      id
      username
      organizations {
        id
        ...OrganizationSubscriptionBasicInfo
      }
    }
  }
  ${ORGANIZATION_SUBSCRIPTION_BASIC_INFO_FRAGMENT}
`;

export const ORGANIZATION_SUBSCRIPTION_BASIC_INFO_BY_NAME = gql`
  query OrganizationSubscriptionBasicInfoByName($name: String!) {
    organization(name: $name) {
      id
      ...OrganizationSubscriptionBasicInfo
    }
  }
`;

export const ORGANIZATION_SUBSCRIPTIONS_BASIC_DETAILS = gql`
  query OrganizationSubscriptionsBasicDetails($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      subscriptions {
        id
        subscriptionType
        billingPeriodStart
        billingPeriodEnd
        plan {
          id
          name
          planType
        }
      }
    }
  }
`;

export const ORGANIZATION_COMPUTE_SECOND_USAGE_INFO = gql`
  query OrganizationComputeSecondUsageInfo(
    $organizationId: ID!
    $timeWindow: TimeWindow!
  ) {
    organization(id: $organizationId) {
      id
      teams {
        id
        computeHours(cached: true, timeWindow: $timeWindow)
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_SUBSCRIPTION_SEATS = gql`
  mutation updateOrganizationSubscriptionSeats(
    $organizationID: ID!
    $seats: Int!
    $clientMutationId: String
  ) {
    updateOrganizationSubscriptionSeats(
      input: {
        organizationID: $organizationID
        seats: $seats
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const SEND_REQUEST_ORG_EMAIL_DOMAIN_VERIFICATION_EMAIL = gql`
  mutation sendRequestOrgEmailDomainVerification(
    $organizationID: ID!
    $clientMutationId: String
  ) {
    sendRequestOrgEmailDomainVerification(
      input: {
        organizationID: $organizationID
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;

export const ORGANIZATION_RUNS_QUERY = gql`
  query OrganizationRuns($orgID: ID!, $userID: ID!) {
    organization(id: $orgID) {
      id
      latestRuns(userID: $userID) {
        edges {
          node {
            id
            ...RunsTableFragment
          }
        }
      }
    }
  }
  ${runsTableFragment}
`;

export const accountProjectsFragment = gql`
  fragment AccountProjectFragment on Project {
    id
    name
    entityName
    access
    totalRuns
    createdAt
    lastActive
    description
    user {
      id
      username
    }
  }
`;

export const ORGANIZATION_PROJECTS_QUERY = gql`
  query OrganizationProjects($orgID: ID!, $userID: ID, $first: Int = 20) {
    organization(id: $orgID) {
      id
      projects(userID: $userID, first: $first, order: "-created_at") {
        edges {
          node {
            id
            ...AccountProjectFragment
          }
        }
      }
    }
  }
  ${accountProjectsFragment}
`;

export const VIEWER_ORGANIZATION_FEATURE_FLAGS = gql`
  query viewerOrganizationFeatureFlags($rampIDType: RampIDType!) {
    viewer {
      id
      organizations {
        id
        name
        featureFlags(rampIDType: $rampIDType) {
          rampKey
          isEnabled
        }
      }
    }
  }
`;

export const ORGANIZATION_FEATURE_FLAGS = gql`
  query organizationFeatureFlags($orgName: String, $rampIDType: RampIDType!) {
    organization(name: $orgName) {
      id
      orgType
      featureFlags(rampIDType: $rampIDType) {
        rampKey
        isEnabled
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_PRIVACY_SETTINGS = gql`
  mutation updateOrganizationPrivacySettings(
    $organizationId: ID!
    $hidden: Boolean
    $privateOnly: Boolean
    $onlyAdminsCanInvite: Boolean
    $disableMagicLinkSharing: Boolean
    $restrictMatchingOrgEmailDomainUsers: Boolean
    $codeSavingDisabled: Boolean
    $clientMutationId: String
  ) {
    updateOrganizationPrivacySettings(
      input: {
        organizationId: $organizationId
        hidden: $hidden
        privateOnly: $privateOnly
        onlyAdminsCanInvite: $onlyAdminsCanInvite
        disableMagicLinkSharing: $disableMagicLinkSharing
        restrictMatchingOrgEmailDomainUsers: $restrictMatchingOrgEmailDomainUsers
        codeSavingDisabled: $codeSavingDisabled
        clientMutationId: $clientMutationId
      }
    ) {
      success
      clientMutationId
    }
  }
`;
